import { cnva } from '~/utils/cn'

// here temporarily
export const navBarOptionStyle = cnva(
  'flex h-9 w-full cursor-pointer items-center gap-2.5 rounded-md p-2.5 text-xs font-medium text-foreground/80 hover:bg-foreground/4 hover:text-highlight active:bg-foreground/6 active:text-highlight',
  {
    variants: {
      variant: {
        default: '',
        currentPage: 'bg-foreground/8 text-highlight',
        currentGroup: 'text-highlight',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export const debounce = (callback: (payload: string) => void, delay = 500) => {
  let timer: NodeJS.Timeout
  return (...args: unknown[]) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      callback(...(args as [string]))
    }, delay)
  }
}
